import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

// export const NETWORK = "mainnet";
export const NETWORK = "devnet";

export const config = new anchor.web3.PublicKey(
  "9CJtfHxGUSwJSsfEDsttvT3Rebsx13RvPBiuBcvGwbYb"
);
export const configUUID = "9CJtfH";

export const candyMachineOwner = new anchor.web3.PublicKey(
  "BSxjjyfhvMYKRJXyLfQbH4czLG1HBhmwcTY5YRFAxuNy"
);
