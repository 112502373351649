import { Connection } from "@solana/web3.js";
import React, { createContext, ReactElement, useContext, useState } from "react";
import { WalletAdapter } from "../wallet/phantom";
import * as anchor from "@project-serum/anchor";
import { candyMachineIdl } from "../common/candyMachineIdl";
import { config, configUUID, programId } from "../common/config";
import { getCandyMachine } from "../actions/mintNFT";


export type CandyMachineContextType = {
  candyMachine: any;
  findCandyMachine: (arg0: Connection, arg1: WalletAdapter) => void;
};
const defaultContextValues = {
  candyMachine: null,
  findCandyMachine: () => {},
};

export const CandyMachineContext =
  createContext<CandyMachineContextType>(defaultContextValues);

type CandyMachineProviderPropsType = { children: ReactElement };

export function CandyMachineProvider({
  children,
}: CandyMachineProviderPropsType): ReactElement {
  const [candyMachine, setCandyMachine] = useState<any>(null);

  async function findCandyMachine(
    connection: Connection,
    wallet: WalletAdapter
  ) {
    // @ts-ignore
    const provider = new anchor.Provider(connection, wallet, {
      preflightCommitment: "processed",
    });
    const anchorProgram = new anchor.Program(
      candyMachineIdl,
      programId,
      provider
    );

    // eslint-disable-next-line
    const [candyMachine, _bump] = await getCandyMachine(config, configUUID);
    const cmp = await anchorProgram.account.candyMachine.fetch(candyMachine);
    setCandyMachine(cmp);
  }
  const value = {
    candyMachine,
    findCandyMachine,
  };
  return (
    <CandyMachineContext.Provider value={value}>
      {children}
    </CandyMachineContext.Provider>
  );
}
export const useCandyMachine = (): CandyMachineContextType =>
  useContext(CandyMachineContext);
